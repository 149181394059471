import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import * as React from 'react';

import ActionButton from 'Components/ActionButton';
import FormRow from 'Components/FormRow';
import { dialogActions, dialogTitle, dialogContent } from '../../../../../../config/theme/componentTheme';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import colors from '../../../../../../config/theme/colors';
import { fm } from 'Components/FormatMessage';
import { makeLog } from 'Utility/logger';
import SearchSpinner from 'Routes/_Patient/components/SearchSpinner';
import {
  formatPartialDate,
  isPartialDate,
  nowPartialDate,
  partialDateFromDate,
  ssnBirthDayToPartialDate,
} from 'neuro-utils';
import { v4 } from 'uuid';
import { isEmpty, omit } from 'ramda';
import {
  search,
  linkAvxPatient,
  fetchLocations,
  createAvxPatient,
  updateAvxPatient,
  fetchAvxPatientInfo,
} from '../functions';
import { TPatient, TProfileData, TLocation } from '../interfaces';
import { PAPTherapyContext } from 'Routes/Treatment/Document';
import { useSelector } from 'react-redux';
import ToolTip from 'Components/ToolTip';
import { deviceTypesToDeviceNamesMap } from './config';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StartView = ({ active }: { active: boolean }): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow headerWidth={1} title="treatment.papTherapy.resMedStatus">
        {fm(`treatment.papTherapy.resMed${active ? 'InUse' : 'NotInUse'}`)}
      </FormRow>
    </React.Fragment>
  );
};

const SearchView = ({
  setDialogOpen,
  setDialogState,
  deviceSerialNumber,
  setDeviceSerialNumber,
}: {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogState: React.Dispatch<React.SetStateAction<TDialogState>>;
  deviceSerialNumber: string;
  setDeviceSerialNumber: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { setResMedIntegrationEnabled } = papTherapyContext;

  const [state, setState] = React.useState<TSearchState>(undefined);
  const [searchResult, setSearchResult] = React.useState<TSearchResult | undefined>(undefined);

  const patientData = useSelector((s: IState) => s.patient.data);

  const searchText = deviceSerialNumber;
  const setSearchText = setDeviceSerialNumber;

  const handleSearch = (): void => {
    setSearchResult(undefined);
    if (typeof deviceSerialNumber === 'string' && searchText.length > 0) {
      setState('searching');
      search(searchText)
        .then((res) => {
          if (res && typeof res === 'object' && !isEmpty(res)) {
            const data = {
              ecn: res.ecn,
              firstName: res.firstName,
              lastName: res.lastName,
              dob: res.dob,
              location: res.location,
              setupDate: res.setupDate,
              device: res.prescription.device,
            };
            setTimeout(() => {
              setState('found');
            }, 200);
            setSearchResult(data);
          } else {
            setSearchResult(undefined);
            setTimeout(() => {
              setState('notFound');
            }, 200);
          }
        })
        .catch((err: Error) => {
          setSearchResult(undefined);
          setTimeout(() => {
            setState('notFound');
          }, 200);
          makeLog('Error', { name: 'AVX patient search', message: 'Response code not 200' }, err);
          return null;
        });
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && typeof searchText === 'string' && searchText.length > 0) {
      handleSearch();
    }
  };

  // Used if there is a deviceType that should match an S9 device but no deviceTypeDesc
  const deviceName =
    searchResult?.device?.deviceType &&
    searchResult.device.deviceType.length === 6 &&
    deviceTypesToDeviceNamesMap.has(searchResult.device.deviceType)
      ? deviceTypesToDeviceNamesMap.get(searchResult.device.deviceType)
      : undefined;

  return (
    <React.Fragment>
      <div style={{ marginBottom: '1rem' /**'3rem'*/ }}>
        <div>{fm('treatment.papTherapy.connectExistingResMed')}</div>
        <div>{fm('treatment.papTherapy.findPatientWithSerialNumber')}</div>
      </div>
      <Container style={{ alignItems: 'center' }}>
        <Item xs={10} style={{ paddingRight: '2rem' }} onKeyUp={handleEnter}>
          <InputHandler
            type="TextField"
            editing={true}
            name="searchText"
            formData={{
              onChange: (values: TOnChangeValues) => {
                const name = Object.keys(values)[0];
                const value = values[name] as string;
                setSearchText(value);
              },
              document: { searchText: searchText },
            }}
            fullWidth
            format={/^\d{0,11}$/}
            placeholder="treatment.papTherapy.enterSerialNumber"
          />
        </Item>
        <Item xs={2}>
          <ActionButtonRounded
            text="general.search"
            width={10}
            height={3}
            fontSize={16}
            onClick={() => handleSearch()}
            filled
            disabled={typeof searchText !== 'string' || searchText.length === 0}
            // uppercase={false}
          />
        </Item>
      </Container>
      {state ? (
        <React.Fragment>
          {
            {
              searching: (
                <Container style={{ height: '28rem', flexDirection: 'column', justifyContent: 'center' }}>
                  <Item xs={12}>
                    <SearchSpinner text="general.searching" />
                  </Item>
                </Container>
              ),
              notFound: (
                <Container
                  style={{
                    height: '28rem',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    rowGap: '2rem',
                  }}
                >
                  <Item style={{ color: colors.primary, fontWeight: 600 }}>
                    {fm('treatment.papTherapy.noPatientWithSerialNumber')}
                  </Item>
                  <Item>
                    <ActionButtonRounded
                      text="treatment.papTherapy.createResMed"
                      onClick={() => setDialogState('edit')}
                      fontSize={16}
                      width={20}
                      height={4}
                      disabled={searchText.length < 11}
                      disabledTooltip={fm('treatment.papTherapy.invalidSerialNumberMessage')}
                      // uppercase={false}
                    />
                  </Item>
                </Container>
              ),
              found: searchResult ? (
                <StyledContainer style={{ justifyContent: 'space-between', minHeight: '28rem' }}>
                  <StyledContainer style={{ flex: 1, justifyContent: 'center', padding: '1rem 0 1rem 0' }}>
                    <div style={{ backgroundColor: colors.lightestGray, height: 'min-content', padding: '1rem' }}>
                      <FormRow headerWidth={6} title="treatment.papTherapy.resMedPatient" bottomMargin={false}>
                        {`${searchResult.lastName} ${searchResult.firstName}`}
                      </FormRow>
                      <FormRow headerWidth={6} title="profile.dateOfBirth" bottomMargin={false}>
                        {`${searchResult.dob}`}
                      </FormRow>
                      <br />
                      <FormRow headerWidth={6} title="treatment.papTherapy.deviceSerialNumber" bottomMargin={false}>
                        {`${searchResult.device ? searchResult.device.serialNo : '-'}`}
                      </FormRow>
                      {searchResult.device?.deviceType || searchResult.device?.deviceTypeDesc ? (
                        <React.Fragment>
                          <FormRow headerWidth={6} title="treatment.papTherapy.deviceModel" bottomMargin={false}>
                            {`${
                              searchResult.device.deviceTypeDesc
                                ? searchResult.device.deviceTypeDesc
                                : deviceName
                                  ? deviceName
                                  : '-'
                            }`}
                          </FormRow>
                          <FormRow headerWidth={6} title="treatment.papTherapy.deviceNumber" bottomMargin={false}>
                            {`${searchResult.device.deviceType ?? '-'}`}
                          </FormRow>
                        </React.Fragment>
                      ) : (
                        <FormRow headerWidth={6} title="treatment.papTherapy.deviceNumber" bottomMargin={false}>
                          {`${searchResult.device ? searchResult.device.deviceNumber ?? '-' : '-'}`}
                        </FormRow>
                      )}
                    </div>
                  </StyledContainer>
                  <div style={{ padding: '1rem 0 1rem 0' }}>
                    <Container
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Item>
                        <ActionButtonRounded
                          text="general.confirm"
                          onClick={() => {
                            linkAvxPatient(searchResult.ecn)
                              .then(() => {
                                setResMedIntegrationEnabled(true);
                                setDialogOpen(false);
                              })
                              .catch(() => {
                                return;
                              });
                          }}
                          fontSize={16}
                          width={15}
                          height={4}
                          disabled={
                            !patientData ||
                            searchResult.dob !== formatPartialDate(ssnBirthDayToPartialDate(patientData.ssn))
                          }
                          disabledTooltip={fm('treatment.papTherapy.invalidDOBMessage')}
                          // uppercase={false}
                        />
                      </Item>
                      <Item>
                        <ActionButtonRounded
                          text="myService.reject"
                          onClick={() => setState(undefined)}
                          fontSize={16}
                          width={15}
                          height={4}
                          colorScheme="error"
                          // uppercase={false}
                        />
                      </Item>
                    </Container>
                  </div>
                </StyledContainer>
              ) : (
                <></>
              ),
            }[state]
          }
        </React.Fragment>
      ) : (
        <Container style={{ height: '28rem' }} />
      )}
    </React.Fragment>
  );
};

type TSearchState = 'searching' | 'found' | 'notFound' | undefined;

type TSearchResult = {
  ecn: TPatient['ecn'];
  firstName: TPatient['firstName'];
  lastName: TPatient['lastName'];
  dob: TPatient['dob'];
  location: TPatient['location'];
  setupDate: TPatient['setupDate'];
  device: TPatient['prescription']['device'];
};

const EditView = ({
  setDialogOpen,
  setDialogState,
  deviceSerialNumber,
}: {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogState: React.Dispatch<React.SetStateAction<TDialogState>>;
  deviceSerialNumber: string;
}): JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { setResMedIntegrationEnabled, ecn } = papTherapyContext;

  // Used to differentiate editing from creating
  const [editing, setEditing] = React.useState<boolean>(false);
  const [editingDevice, setEditingDevice] = React.useState<boolean>(false);
  const [clearTooltipOpen, setClearTooltipOpen] = React.useState<boolean>(false);
  const [saveTooltipOpen, setSaveTooltipOpen] = React.useState<boolean>(false);

  const patientData = useSelector((s: IState) => s.patient.data);

  // TODO: Is this initialization of avxUserId ok?
  const avxUserId = v4();
  const [profileData, setProfileData] = React.useState<Partial<TProfileData>>({});
  const [locations, setLocations] = React.useState<TLocation[]>([]);

  const handleChange = (e: TOnChangeValues) => {
    const name = Object.keys(e)[0];
    const value = e[name];
    setProfileData({ ...profileData, [name]: value });
  };

  const handleClear = () => {
    setProfileData({
      ...profileData,
      deviceSerial: undefined,
      deviceNumber: undefined,
      deviceType: undefined,
      deviceTypeDesc: undefined,
    });
  };

  // Set locations
  React.useEffect(() => {
    fetchLocations()
      .then((locs) => {
        if (Array.isArray(locs)) {
          setLocations(locs.filter((l) => l && l.id && l.name));
        }
      })
      .catch(() => {
        return;
      });
  }, []);

  // Set editing data
  React.useEffect(() => {
    if (ecn) {
      setEditing(true);
      fetchAvxPatientInfo(ecn)
        .then((res) => {
          if (res && typeof res === 'object' && !isEmpty(res)) {
            const data: Partial<TProfileData> = {
              location: res.location,
              setupDate: partialDateFromDate(new Date(res.setupDate)),
              deviceSerial: res.prescription?.device?.serialNo,
              avxUserId: undefined,
            };

            if (data.deviceSerial) {
              // If deviceType is received instead of deviceNumber, map it to deviceNumber
              data.deviceNumber = res.prescription?.device?.deviceType ?? res.prescription?.device?.deviceNumber;
              data.deviceTypeDesc = res.prescription?.device?.deviceTypeDesc;
            } else {
              setEditingDevice(true);
            }

            setProfileData(data);
          }
        })
        .catch(() => {
          return;
        });
    } else {
      setProfileData({
        location: undefined,
        setupDate: nowPartialDate(),
        deviceSerial: deviceSerialNumber,
        deviceNumber: undefined,
        deviceType: undefined,
        deviceTypeDesc: undefined,
        avxUserId: avxUserId,
      });
    }
  }, []);

  const isDataValid =
    profileData.location &&
    isPartialDate(profileData.setupDate) &&
    profileData.deviceSerial?.length === 11 &&
    ((editing && profileData.deviceType && profileData.deviceTypeDesc) || profileData.deviceNumber?.length === 3) &&
    (editing || profileData.avxUserId);

  // Data used when creating a new ResMed patient
  const createData = omit(['deviceType', 'deviceTypeDesc'], profileData);

  // Only location, setupDate, deviceSerial and deviceNumber can be updated
  const omittedUpdateDataFields = ['deviceType', 'deviceTypeDesc', 'avxUserId'];
  // Data used when editing an existing ResMed patient
  const updateData = ecn ? { ecn, ...omit(omittedUpdateDataFields, profileData) } : undefined;

  const profileDataHasDeviceTypeOrDesc = !!(profileData.deviceType || profileData.deviceTypeDesc);

  // Used if there is a deviceType that should match an S9 device but no deviceTypeDesc
  const deviceName =
    profileData.deviceType &&
    profileData.deviceType.length === 6 &&
    deviceTypesToDeviceNamesMap.has(profileData.deviceType)
      ? deviceTypesToDeviceNamesMap.get(profileData.deviceType)
      : undefined;

  const mounted = 'location' in profileData;

  // Show deviceTypeDesc as deviceModel if editing and device has not been cleared
  const showDeviceTypeDesc = editing && !editingDevice;
  /// Show deviceNumber input if creating or if device has been cleared
  const showDeviceNumber = !editing || editingDevice;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '60rem',
      }}
    >
      <div>
        <div style={{ marginBottom: '3rem' }}>
          <span>{fm(`treatment.papTherapy.${editing ? 'edit' : 'connectNew'}ResMed`)}</span>
        </div>
        <FormRow headerWidth={4} title="treatment.papTherapy.patientName">
          <span style={{ fontWeight: 600 }}>{`${patientData?.lastNames ?? ''} ${patientData?.firstNames ?? ''}`}</span>
        </FormRow>
        <FormRow headerWidth={4} title="profile.dateOfBirth">
          <span style={{ fontWeight: 600 }}>
            {patientData?.ssn ? formatPartialDate(ssnBirthDayToPartialDate(patientData.ssn)) : '-'}
          </span>
        </FormRow>
        <FormRow headerWidth={4} title="treatment.papTherapy.location">
          <InputHandler
            type="Select"
            editing={true}
            name="location"
            formData={{
              onChange: handleChange,
              document: { location: profileData.location },
            }}
            options={locations.map((l) => l.name)}
            placeholder="treatment.papTherapy.selectLocation"
          />
        </FormRow>
        <FormRow headerWidth={4} title="treatment.papTherapy.setupDate">
          <InputHandler
            type="PartialDate"
            editing={true}
            name="setupDate"
            formData={{
              onChange: handleChange,
              document: { setupDate: profileData.setupDate },
            }}
            isNotCancellable
          />
        </FormRow>
        <FormRow headerWidth={4} title="treatment.papTherapy.deviceSerialNumber">
          <InputHandler
            type="TextField"
            editing={mounted && editingDevice}
            name="deviceSerial"
            formData={{
              onChange: handleChange,
              document: { deviceSerial: profileData.deviceSerial },
            }}
            format={/^\d{0,11}$/}
            width={23}
            placeholder="treatment.papTherapy.enterSerialNumber"
          />
        </FormRow>
        {showDeviceTypeDesc && (
          <FormRow headerWidth={4} title="treatment.papTherapy.deviceModel">
            <InputHandler
              type="TextField"
              editing={false}
              name="deviceTypeDesc"
              formData={{
                onChange: handleChange,
                document: {
                  deviceTypeDesc: profileData.deviceTypeDesc ? profileData.deviceTypeDesc : deviceName,
                },
              }}
              width={23}
            />
          </FormRow>
        )}
        {showDeviceNumber && (
          <FormRow headerWidth={4} title="treatment.papTherapy.deviceNumber">
            <InputHandler
              type="TextField"
              editing={mounted}
              name="deviceNumber"
              formData={{
                onChange: handleChange,
                document: { deviceNumber: profileData.deviceNumber },
              }}
              format={/^\d{0,3}$/}
              width={23}
              placeholder="treatment.papTherapy.enterDeviceNumber"
            />
          </FormRow>
        )}
      </div>
      <Container
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '3rem',
        }}
      >
        <Item>
          <ToolTip
            title={fm('treatment.papTherapy.createProfileTooltip')}
            content={
              <div onBlur={() => setSaveTooltipOpen(false)}>
                <ActionButtonRounded
                  text={editing ? 'general.save' : 'treatment.papTherapy.createProfile'}
                  onClick={() => {
                    if (editing && updateData) {
                      updateAvxPatient(updateData)
                        .then((res) => {
                          if (res) {
                            setEditing(false);
                            setDialogOpen(false);
                          } else {
                            setSaveTooltipOpen(true);
                          }
                        })
                        .catch(() => {
                          return;
                        });
                    } else {
                      createAvxPatient(createData as TProfileData)
                        .then((res) => {
                          if (res) {
                            setResMedIntegrationEnabled(true);
                            setDialogOpen(false);
                          } else {
                            setSaveTooltipOpen(true);
                          }
                        })
                        .catch(() => {
                          return;
                        });
                    }
                  }}
                  fontSize={16}
                  width={15}
                  height={4}
                  disabled={!isDataValid}
                  // uppercase={false}
                />
              </div>
            }
            open={saveTooltipOpen}
          />
        </Item>
        <Item>
          {editing ? (
            <ToolTip
              title={fm('treatment.papTherapy.clearResMedDeviceTooltip')}
              content={
                <div onBlur={() => setClearTooltipOpen(false)}>
                  <ActionButtonRounded
                    text="treatment.papTherapy.clearResMedDevice"
                    onClick={() => {
                      if (!clearTooltipOpen) {
                        setClearTooltipOpen(true);
                      } else {
                        handleClear();
                        setClearTooltipOpen(false);
                        setEditingDevice(true);
                      }
                    }}
                    fontSize={16}
                    width={15}
                    height={4}
                    colorScheme="error"
                    disabled={
                      !(profileData.deviceSerial && (profileData.deviceNumber || profileDataHasDeviceTypeOrDesc))
                    }
                    // uppercase={false}
                  />
                </div>
              }
              open={clearTooltipOpen}
            />
          ) : (
            <ActionButtonRounded
              text="general.cancel"
              onClick={() => setDialogState('search')}
              fontSize={16}
              width={15}
              height={4}
              colorScheme="error"
              // uppercase={false}
            />
          )}
        </Item>
      </Container>
    </div>
  );
};

// 'start' is currently unused
type TDialogState = 'start' | 'search' | 'edit';

const ResMedDialog = (): JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { resMedIntegrationEnabled, resMedDialogOpen, setResMedDialogOpen } = papTherapyContext;

  const dialogOpen = resMedDialogOpen;
  const setDialogOpen = setResMedDialogOpen;
  const integrationEnabled = resMedIntegrationEnabled;

  const defaultState = integrationEnabled ? 'edit' : 'search';

  const [state, setState] = React.useState<TDialogState>(defaultState);

  const [deviceSerialNumber, setDeviceSerialNumber] = React.useState<string>('');

  return (
    <Dialog
      open={dialogOpen}
      maxWidth={
        {
          start: 'sm',
          search: 'xs',
          edit: 'sm',
        }[state] as Breakpoint
      }
      fullWidth
      PaperProps={{ square: true }}
    >
      <DialogTitle style={dialogTitle}>{fm('treatment.papTherapy.resMedIntegration')}</DialogTitle>
      <DialogContent style={dialogContent}>
        {
          {
            start: <StartView active={integrationEnabled ? true : false} />,
            search: (
              <SearchView
                setDialogOpen={setDialogOpen}
                setDialogState={setState}
                deviceSerialNumber={deviceSerialNumber}
                setDeviceSerialNumber={setDeviceSerialNumber}
              />
            ),
            edit:
              deviceSerialNumber || integrationEnabled ? (
                <EditView
                  setDialogOpen={setDialogOpen}
                  setDialogState={setState}
                  deviceSerialNumber={deviceSerialNumber ?? ''}
                />
              ) : (
                <></>
              ),
          }[state]
        }
      </DialogContent>
      {state === 'start' && (
        <DialogActions style={dialogActions}>
          <Container justifyContent={'space-between'}>
            <Item>
              <ActionButtonRounded
                text={`treatment.papTherapy.${integrationEnabled ? 'edit' : 'connect'}ResMed`}
                onClick={() => setState(integrationEnabled ? 'edit' : 'search')}
                fontSize={16}
                width={30}
                height={4}
              />
            </Item>
            <Item>
              <ActionButtonRounded
                text="treatment.papTherapy.clearResMedDevice"
                onClick={() => ''}
                fontSize={16}
                width={30}
                height={4}
                colorScheme="error"
                disabled
              />
            </Item>
          </Container>
        </DialogActions>
      )}
      <DialogActions style={{ ...dialogActions, paddingTop: '1rem', paddingBottom: '2rem' }}>
        <ActionButton
          text="header.aboutDialog.close"
          width={10}
          height={3}
          fontSize={16}
          onClick={() => {
            setDeviceSerialNumber('');
            setDialogOpen(false);
            setTimeout(() => setState(defaultState), 500);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ResMedDialog;
