import * as React from 'react';
import { formatPartialDate, isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { path } from 'ramda';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import { fm } from 'Components/FormatMessage';
import { categorizeTreatmentsByStatus, isContinuous, TDocTypes, TDocument } from '../../utils';
import { PAPTherapyContext } from '..';
import { useAppSelector } from 'Store/index';

/**
 * Treatment history headers that depend on treatment type and active diagnosis
 */
const TreatmentHistoryHeaders = ({
  type,
  hasContinuousOtherTreatments,
  totalUsageHours,
  resMedDevice,
}: {
  type: string;
  hasContinuousOtherTreatments?: boolean;
  totalUsageHours: string | undefined;
  resMedDevice: string | undefined;
}): JSX.Element => {
  const hasTotalUsageHours = totalUsageHours && resMedDevice;
  switch (type) {
    case 'patientDoesNotWantRespiratorySupportTherapy':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.patientDoesNotWantRespiratorySupportTherapy.startDate')}</Item>
          <Item xs={3}>{fm('treatment.patientDoesNotWantRespiratorySupportTherapy.endDate')}</Item>
        </React.Fragment>
      );
    case 'oxygenTherapy':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.startDate')}</Item>
          <Item xs={4}>{fm('treatment.endDate')}</Item>
          <Item xs={2}>{fm('treatment.oxygenTherapy.oxygenTherapyType')}</Item>
        </React.Fragment>
      );
    case 'hfncTherapy':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.startDate')}</Item>
          <Item xs={4}>{fm('treatment.endDate')}</Item>
          <Item xs={2}>{fm('treatment.hfncTherapy.settings.HFNCTreatmentType')}</Item>
        </React.Fragment>
      );
    case 'papTherapy':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.startDate')}</Item>
          <Item xs={hasTotalUsageHours ? 2 : 4}>{fm('treatment.endDate')}</Item>
          <Item xs={2}>{fm('treatment.papTherapy.device')}</Item>
          {hasTotalUsageHours && <Item xs={2}>{fm('treatment.papTherapy.totalUsageHours')}</Item>}
        </React.Fragment>
      );
    case 'madTherapy':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.startDate')}</Item>
          <Item xs={4}>{fm('treatment.endDate')}</Item>
          <Item xs={2}>{fm('treatment.latestControl')}</Item>
        </React.Fragment>
      );
    case 'surgicalTreatment':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.surgicalTreatment.operationDate')}</Item>
          <Item xs={4}>{fm('treatment.surgicalTreatment.operationType')}</Item>
          <Item xs={2}>{fm('treatment.latestControl')}</Item>
        </React.Fragment>
      );
    case 'otherTreatment':
      return (
        <React.Fragment>
          <Item xs={3}>{fm('treatment.startDate')}</Item>
          {hasContinuousOtherTreatments ? <Item xs={2}>{fm('treatment.endDate')}</Item> : null}
          <Item xs={2}>{fm('treatment.treatmentType')}</Item>
          <Item xs={2}>{fm('treatment.madTherapy.controls.details')}</Item>
        </React.Fragment>
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
};

/**
 * Treatment history items that depend on treatment type
 */
const TreatmentHistoryItems = ({
  d,
  type,
  totalUsageHours,
  resMedDevice,
}: {
  d: TDocument;
  type: string;
  totalUsageHours: string | undefined;
  resMedDevice: string | undefined;
}): JSX.Element => {
  const hasTotalUsageHours = totalUsageHours && resMedDevice;
  switch (type) {
    case 'oxygenTherapy':
      return (
        <Item xs={2}>
          {path(['oxygenTherapyType'], d) ? fm(`treatment.oxygenTherapy.opts.${path(['oxygenTherapyType'], d)}`) : '-'}
        </Item>
      );
    case 'hfncTherapy':
      return (
        <Item xs={2} style={{ fontWeight: 600 }}>
          {path(['settings', 0, 'HFNCTreatmentType'], d)
            ? fm(`treatment.hfncTherapy.settings.opts.${path(['settings', 0, 'HFNCTreatmentType'], d)}`)
            : '-'}
        </Item>
      );
    case 'papTherapy': {
      const device = resMedDevice
        ? resMedDevice
        : path(['devices', 0, 'type'], d)
          ? fm(`treatment.papTherapy.opts.${path(['devices', 0, 'type'], d)}`)
          : '-';
      return (
        <React.Fragment>
          {hasTotalUsageHours ? (
            <React.Fragment>
              <Item xs={2}>{device}</Item>
              <Item xs={true}>{totalUsageHours}</Item>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Item xs={2} />
              <Item xs={2}>{device}</Item>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    case 'surgicalTreatment': {
      const controls: Array<ISurgicalTreatmentControl> | undefined =
        path(['controlVisitsJawSurgery'], d) || path(['controlVisits'], d);
      const controlsSortedDescending = controls
        ? controls.toSorted((n1, n2) => sortPartialDate(n2.date, n1.date))
        : undefined;
      const latestGoneControl = controlsSortedDescending?.find((c) => partialDateToValue(c.date) <= Date.now());
      return (
        <>
          <Item xs={4}>
            {path(['operationType'], d) ? fm(`treatment.surgicalTreatment.opts.${path(['operationType'], d)}`) : '-'}
          </Item>
          <Item xs={2}>{latestGoneControl ? formatPartialDate(latestGoneControl.date) : '-'}</Item>
        </>
      );
    }
    case 'otherTreatment':
      return (
        <React.Fragment>
          <Item xs={2}>
            {path(['otherTreatmentType'], d)
              ? fm(`treatment.otherTreatment.opts.${path(['otherTreatmentType'], d)}`)
              : '-'}
          </Item>
          <Item xs={true}>{path(['otherTreatmentDetails'], d) ? path(['otherTreatmentDetails'], d) : '-'}</Item>
        </React.Fragment>
      );
    case 'madTherapy': {
      const controls: Array<IMADControl> | undefined = path(['controls'], d);
      const controlsSortedDescending = controls
        ? controls.toSorted((n1, n2) => sortPartialDate(n2.date, n1.date))
        : undefined;
      const latestGoneControl = controlsSortedDescending?.find((c) => partialDateToValue(c.date) <= Date.now());
      return <Item xs={2}>{latestGoneControl ? formatPartialDate(latestGoneControl.date) : '-'}</Item>;
    }
    default:
      return <Item />;
  }
};

/**
 * Common and treatment-specific history items combined
 */
const TreatmentHistory = ({
  d,
  startEdit,
  view,
  type,
  isContinuous,
  hasContinuousOtherTreatments,
  totalUsageHours,
  resMedDevice,
}: {
  d: TDocument;
  startEdit: ITreatmentHistoryProps['startEdit'];
  view?: ITreatmentHistoryProps['view'];
  type: string;
  isContinuous: (d: TDocument) => boolean;
  hasContinuousOtherTreatments: boolean;
  totalUsageHours: string | undefined;
  resMedDevice: string | undefined;
}): JSX.Element => {
  const endDateSize =
    type === 'papTherapy' || (type === 'otherTreatment' && hasContinuousOtherTreatments)
      ? 2
      : type === 'patientDoesNotWantRespiratorySupportTherapy'
        ? true
        : 4;

  return (
    <React.Fragment>
      <Item xs={3} style={{ fontWeight: 600 }}>
        {d.date ? formatPartialDate(d.date) : '-'}
      </Item>
      {isContinuous(d) || hasContinuousOtherTreatments ? (
        <Item xs={endDateSize} style={{ fontWeight: 600 }}>
          {isPartialDate(path(['endDate'], d)) ? formatPartialDate(path(['endDate'], d)) : isContinuous(d) ? '-' : ''}
        </Item>
      ) : null}
      <TreatmentHistoryItems d={d} type={type} totalUsageHours={totalUsageHours} resMedDevice={resMedDevice} />
      <Item xs={3}>{startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}</Item>
    </React.Fragment>
  );
};

/**
 * Component for rendering same looking history section for each of the treatments
 */
export const TreatmentHistorySection = ({ documents, startEdit, type, view }: ITreatmentHistoryProps): JSX.Element => {
  const docs = documents.filter((d: any) => d._type === type) as TDocument[];

  /**
   * Check if patient has continuous other treatments which are exclusive to sleep apnea platform
   * This is used to correctly format the history view between sleep apnea and respiratory failure diagnoses
   */
  const hasContinuousOtherTreatments = docs?.some(
    (d: TDocument) =>
      d._type === 'otherTreatment' &&
      [undefined, 'postureTreatment', 'otherTreatment'].includes(path(['otherTreatmentType'], d)),
  );

  const treatmentsByStatus = categorizeTreatmentsByStatus(docs, type);

  const activeDocs = treatmentsByStatus.active;
  const endedDocs = treatmentsByStatus.ended;
  const futureDocs = treatmentsByStatus.future;
  const notStartedDocs = treatmentsByStatus.notStarted;

  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { totalUsageHours } = papTherapyContext;

  const readonlyDocuments = useAppSelector((s: IState) => s.readonly.documents);
  const resMedDevices = readonlyDocuments.filter((d) => d._type === 'resMedDevice') as IPapDevice[];
  const resMedDevice =
    Array.isArray(resMedDevices) && resMedDevices.length > 0 ? resMedDevices[0].deviceModel : undefined;

  return (
    <React.Fragment>
      {/** Render active treatments here */}
      {activeDocs.length > 0 && (
        <React.Fragment>
          {type !== 'patientDoesNotWantRespiratorySupportTherapy' && (
            <HistoryRowSubHeader header={fm('treatment.activeTreatments')} fontSize="small" />
          )}
          <HistoryRowListing
            documents={activeDocs}
            headers={
              <TreatmentHistoryHeaders
                type={type}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            }
            contentFormat={(d: TDocument): JSX.Element => (
              <TreatmentHistory
                d={d}
                startEdit={startEdit}
                type={type}
                view={view}
                isContinuous={isContinuous}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            )}
          />
        </React.Fragment>
      )}
      {/** Render ended treatments here */}
      {endedDocs.length > 0 && (
        <React.Fragment>
          <HistoryRowSubHeader
            header={
              type === 'surgicalTreatment' || type === 'otherTreatment'
                ? fm('treatment.previousTreatments')
                : fm('treatment.endedTreatments')
            }
            fontSize="small"
          />
          <HistoryRowListing
            documents={endedDocs}
            headers={
              <TreatmentHistoryHeaders
                type={type}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            }
            contentFormat={(d: TDocument): JSX.Element => (
              <TreatmentHistory
                d={d}
                startEdit={startEdit}
                view={view}
                type={type}
                isContinuous={isContinuous}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            )}
          />
        </React.Fragment>
      )}
      {/** Render future treatments here */}
      {futureDocs.length > 0 && (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('treatment.upcomingTreatments')} fontSize="small" />
          <HistoryRowListing
            documents={futureDocs}
            headers={
              <TreatmentHistoryHeaders
                type={type}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            }
            contentFormat={(d: TDocument): JSX.Element => (
              <TreatmentHistory
                d={d}
                startEdit={startEdit}
                type={type}
                view={view}
                isContinuous={isContinuous}
                hasContinuousOtherTreatments={hasContinuousOtherTreatments}
                totalUsageHours={totalUsageHours}
                resMedDevice={resMedDevice}
              />
            )}
          />
        </React.Fragment>
      )}
      {/** Render not started treatments here */}
      {notStartedDocs.length > 0 && (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('treatment.madTherapy.treatmentNotStarted')} fontSize="small" />
          <HistoryRowListing
            documents={notStartedDocs}
            headers={
              <React.Fragment>
                <Item xs={3}>{fm('treatment.madTherapy.treatmentDecisionDate')}</Item>
                <Item xs={3}>{fm('treatment.madTherapy.reasonForNotStartingTreatment')}</Item>
                <Item xs={6} />
              </React.Fragment>
            }
            contentFormat={(d: TDocument): JSX.Element => (
              <React.Fragment>
                <Item xs={3}>{'treatmentDecisionDate' in d ? formatPartialDate(d.treatmentDecisionDate) : '-'}</Item>
                <Item xs={3}>
                  {((d as IMADTherapy).reasonForNotStartingTreatment || []).length > 0
                    ? ((d as IMADTherapy).reasonForNotStartingTreatment || []).map((r: string, i: number) => (
                        <span key={r}>
                          {i !== 0 && ', '}
                          {fm('treatment.madTherapy.opts.' + r)}
                        </span>
                      ))
                    : '-'}
                </Item>
                <Item xs={3} />
                <Item xs={3}>
                  {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
                </Item>
              </React.Fragment>
            )}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

interface ITreatmentHistoryProps {
  documents: Array<TDocument>;
  startEdit: any;
  type: TDocTypes;
  view: IView | undefined;
}
