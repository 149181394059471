import { calculatePartialDateDiffenceDuration, isPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
  patientHasRespiratoryFailureDiagnosis as checkIfPatientHasRespiratoryFailure,
  patientHasSleepApneaDiagnosis as checkIfPatientHasSleepApnea,
} from '../../../utils';
import { EndForm } from '../components';
import Devices from './Devices';
import ImplementationMethods from './ImplementationMethods';
import Settings from './Settings';
import TreatmentResponses from './TreatmentResponses';
import ResMedDialog from './ResMedDialog';
import FormSectionBar from 'Components/FormSectionBar';
import { Collapse } from '@mui/material';
import { PAPTherapyContext } from '../..';
import ResMedDevices from './Devices/ResMedDevices';
import ResMedSettings from './Settings/ResMedSettings';
import ResMedTreatmentResponses from './TreatmentResponses/ResMedTreatmentResponses';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { useAppSelector } from 'Store/index';

const locPath = 'treatment.papTherapy.';

const ConditionalCollapseSection = ({
  condition,
  header,
  content,
}: {
  condition: boolean;
  header: JSX.Element;
  content: JSX.Element;
}): JSX.Element => {
  const [sectionOpen, setSectionOpen] = React.useState<boolean>(false);

  return condition ? (
    <React.Fragment>
      <div style={{ cursor: 'pointer' }} onClick={(): void => setSectionOpen(!sectionOpen)}>
        {React.cloneElement(header, { open: sectionOpen })}
      </div>
      <Collapse in={sectionOpen} collapsedSize={'2rem'}>
        {content}
      </Collapse>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {header}
      {content}
    </React.Fragment>
  );
};

const PAPTherapy = ({ formData, documents = [], editing, view }: IFormContext<IPAPTherapy, any>): JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { resMedIntegrationAvailable, resMedIntegrationEnabled, totalUsageHours } = papTherapyContext;

  const diagnosisDocs: Array<IDiagnosis> = (documents ?? []).filter((d) => d._type === 'diagnosis');

  const patientHasSleepApneaDiagnosis = checkIfPatientHasSleepApnea(diagnosisDocs);
  const patientHasRespiratoryFailureDiagnosis = checkIfPatientHasRespiratoryFailure(diagnosisDocs);
  const onlyRespFailDiag = diagnosisDocs.every(
    (d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9' || d.diagnosis === 'respiratoryFailureSuspicion',
  );

  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  let isAcute: boolean;
  let referralDate: PartialDate | undefined;
  if (patientHasRespiratoryFailureDiagnosis && !patientHasSleepApneaDiagnosis) {
    isAcute = checkIfAcuteTreatmentStart(documents, 'respiratoryFirstVisit');
    referralDate = getDateOfReferral(documents, 'respiratoryFirstVisit');
  } else {
    isAcute = checkIfAcuteTreatmentStart(documents);
    referralDate = getDateOfReferral(documents);
  }

  const respiratoryFirstVisitDocs: Array<IRespiratoryFirstVisit> = (documents ?? []).filter(
    (d) => d._type === 'respiratoryFirstVisit',
  );

  const firstVisit = (
    docs: Array<ISleepApneaFirstVisit | IRespiratoryFirstVisit>,
  ): ISleepApneaFirstVisit | IRespiratoryFirstVisit | undefined => {
    return Array.isArray(docs) && docs.length === 1 ? docs[0] : undefined;
  };

  const respiratoryFirstVisit = (firstVisit(respiratoryFirstVisitDocs) ?? {}) as IRespiratoryFirstVisit;

  const sleepDocs: Array<ISleepPolygraphy | ISleepStudy> = (documents ?? [])
    .filter((d) => ['sleepPolygraphy', 'sleepStudy'].includes(d._type))
    .sort((a: ISleepPolygraphy | ISleepStudy, b: ISleepPolygraphy | ISleepStudy) => sortPartialDate(b.date, a.date));
  const latestSleepPolygraphyOrStudy = sleepDocs[0] ?? {};

  const readonlyDocuments = useAppSelector((s: IState) => s.readonly.documents);

  const resMedDevices = readonlyDocuments.filter((d) => d._type === 'resMedDevice');
  const resMedSettings = readonlyDocuments.filter((d) => d._type === 'resMedSetting');
  const resMedTreatmentResponses = readonlyDocuments.filter((d) => d._type === 'resMedTreatmentResponse');

  return (
    <React.Fragment>
      {resMedIntegrationAvailable && <ResMedDialog />}
      <FormSection header={''} headerFormat={false}>
        <FormRow
          title={locPath + 'treatmentDecisionDate'}
          condition={patientHasSleepApneaDiagnosis && !patientHasRespiratoryFailureDiagnosis}
        >
          <InputHandler
            type="PartialDate"
            editing={!view?.viewing}
            name="treatmentDecisionDate"
            formData={formData}
            isNotCancellable={true}
            dateDefault="now"
          />
        </FormRow>

        <FormRow
          title={locPath + 'delayBetweenTheArrivalOfSleepApneaSuspicionReferralAndTreatmentDecision'}
          condition={!isAcute && isFirstDoc && patientHasSleepApneaDiagnosis}
        >
          {isPartialDate(referralDate) && isPartialDate(formData.document.treatmentDecisionDate)
            ? fm('general.days', {
                N: calculatePartialDateDiffenceDuration('days', referralDate, formData.document.treatmentDecisionDate)
                  .days,
              })
            : '-'}
        </FormRow>
        <FormRow title="treatment.papTherapy.date">
          <InputHandler
            type="PartialDate"
            editing={!view?.viewing}
            name="date"
            formData={formData}
            dateDefault={!(patientHasSleepApneaDiagnosis && !patientHasRespiratoryFailureDiagnosis) ? 'now' : undefined}
            isNotCancellable={!(patientHasSleepApneaDiagnosis && !patientHasRespiratoryFailureDiagnosis)}
            dateHook={{
              dateHookFloor: formData.document.treatmentDecisionDate,
              dateHookCeiling: formData.document.endDate,
            }}
          />
        </FormRow>
        <FormRow
          title={locPath + 'delayBetweenTheArrivalOfRespiratoryFailureSuspicionReferralAndTreatmentStart'}
          condition={!isAcute && isFirstDoc && patientHasRespiratoryFailureDiagnosis}
        >
          {isPartialDate(respiratoryFirstVisit.dateOfReferral) && isPartialDate(formData.document.date)
            ? fm('general.days', {
                N: calculatePartialDateDiffenceDuration(
                  'days',
                  respiratoryFirstVisit.dateOfReferral,
                  formData.document.date,
                ).days,
              })
            : '-'}
        </FormRow>
        <FormRow
          title={locPath + 'delayBetweenTreatmentDecisionAndTreatmentStart'}
          condition={patientHasSleepApneaDiagnosis && !patientHasRespiratoryFailureDiagnosis}
        >
          {isPartialDate(formData.document.treatmentDecisionDate) && isPartialDate(formData.document.date)
            ? fm('general.days', {
                N: calculatePartialDateDiffenceDuration(
                  'days',
                  formData.document.treatmentDecisionDate as PartialDate,
                  formData.document.date,
                ).days,
              })
            : '-'}
        </FormRow>
        <FormRow
          title={locPath + 'delayBetweenSleepPolygraphyOrStudyAndTreatmentStart'}
          condition={patientHasSleepApneaDiagnosis}
        >
          {isPartialDate(latestSleepPolygraphyOrStudy.date) && isPartialDate(formData.document.date)
            ? fm('general.days', {
                N: calculatePartialDateDiffenceDuration(
                  'days',
                  latestSleepPolygraphyOrStudy.date,
                  formData.document.date,
                ).days,
              })
            : '-'}
        </FormRow>
        <FormRow title={locPath + 'treatmentStartLocation'}>
          <InputHandler
            type="Radio"
            editing={!view?.viewing}
            name="treatmentStartLocation"
            formData={formData}
            options={['hospitalWardOrOutpatientClinic', 'primaryHealthCare', 'privateSector']}
            optionFormatter={(name: string | number): JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
          />
        </FormRow>
        <FormRow title={locPath + 'selfInitiated'}>
          <InputHandler
            type="Radio"
            editing={!view?.viewing}
            name="selfInitiated"
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={(name: string | number): JSX.Element => fm(`treatment.opts.yesNoUnknown.${name}`)}
          />
        </FormRow>
      </FormSection>
      <FormSectionBar header={locPath + 'implementationMethod'} />
      <FormSection>
        <ImplementationMethods formData={formData} viewing={!!view?.viewing} />
      </FormSection>
      {/** ResMed */}
      {resMedIntegrationAvailable && resMedIntegrationEnabled && (
        <React.Fragment>
          <FormSectionBar header={locPath + 'opts.ResMed'} />
          <FormSection header={locPath + 'device'}>
            <React.Fragment>
              <ResMedDevices devices={resMedDevices} />
              {totalUsageHours && resMedDevices.length > 0 && (
                <FormRow title={locPath + 'totalUsageHours'}>
                  <span style={{ fontWeight: 600 }}>{totalUsageHours}</span>
                </FormRow>
              )}
            </React.Fragment>
          </FormSection>
          <FormSection header={locPath + 'settings'}>
            <ResMedSettings settings={resMedSettings} />
          </FormSection>
          <FormSection header={locPath + 'treatmentResponse'}>
            <ResMedTreatmentResponses treatmentResponses={resMedTreatmentResponses} formData={formData} />
          </FormSection>
        </React.Fragment>
      )}
      {/** Other */}
      <ConditionalCollapseSection
        condition={resMedIntegrationAvailable && resMedIntegrationEnabled}
        header={
          <FormSectionBar
            header={
              locPath +
              { true: 'otherDevicesSettingsAndTreatmentResponses', false: 'devicesSettingsAndTreatmentResponses' }[
                resMedIntegrationEnabled.toString()
              ]
            }
          />
        }
        content={
          <React.Fragment>
            <FormSection header={locPath + 'device'}>
              <Devices formData={formData} disableCpapDevices={onlyRespFailDiag} viewing={!!view?.viewing} />
            </FormSection>
            <FormSection header={locPath + 'settings'}>
              <Settings formData={formData} viewing={!!view?.viewing} />
            </FormSection>
            <FormSection header={locPath + 'treatmentResponse'}>
              <TreatmentResponses formData={formData} viewing={!!view?.viewing} />
            </FormSection>
          </React.Fragment>
        }
      />
      <FormSectionBar header="treatment.treatmentEndedTitle" />
      <EndForm formData={formData} edit={!view?.viewing} type="papTherapy" />
    </React.Fragment>
  );
};

export default withFormContext(PAPTherapy);
